import _             from 'lodash';
import TinymceEditor from './tinymce_editor';


export default class ContentTranslationEditor {
  constructor(options) {
    this.el                 = options.translation;
    this.content            = options.content;
    this.tab                = options.tab;
    this.languages          = options.languages;
    this.languageField      = this.el.find('input.language');
    this.autoAbstractFields = this.el.find('.abstract input[type=radio]');
    this.abstractField      = this.el.find('.abstract textarea');
    this.dropdown           = this.tab.find('.dropdown-menu');

    this.autosetLanguage();

    this.setupEditor(options);

    this.tab.find('.set').click((e) => {
      e.preventDefault();
      this.activate();
    });

    this.dropdown.find('a.set-language').click((e) => {
      e.preventDefault();

      const newLang = $(e.currentTarget).data('language');
      this.setLanguage(newLang);
    });

    this.tab.find('.delete').click((e) => {
      e.preventDefault();

      if (confirm("Are you sure?")) {
        this.destroy();
      }

    });

    this.setupAbstract();
  }

  activate() {
    for (let editor of this.content.translationEditors) {
      editor.deActivate();
    }

    this.tab.children('button').removeClass('btn-outline-secondary').addClass('btn-outline-primary');
    this.el.addClass('active');
  }

  isActive() {
    return this.el.hasClass('active');
  }

  destroy() {
    this.content.removeTranslation(this);
    this.el.remove();
    this.tab.remove();
  }

  deActivate() {
    this.tab.children('button').removeClass('btn-outline-primary').addClass('btn-outline-secondary');
    this.el.removeClass('active');
  }

  autosetLanguage() {
    if (this.el.data('persisted')) {
      this.setLanguage(this.languageField.val());
    } else {
      const usedLanguages = _.map(this.content.translationEditors, 'language');
      const availableLanguages = _.difference(this.languages, usedLanguages);

      this.setLanguage(availableLanguages[0] || this.languages[0]);
    }
  }

  setLanguage(language, setField = true) {
    this.language = language;
    this.tab.find('.language').text(this.language.toUpperCase());
    if (setField) {
      this.languageField.val(this.language);
    }
  }

  setupAbstract() {
    const change = () => {
      this.abstractField.prop('disabled', this.autoAbstractFields.filter(':checked').val() === 'true');
    };

    this.autoAbstractFields.change(change);
    change();
  }

  setupEditor(options) {
    this.editor = new TinymceEditor({
      el:         this.el.find('.fields .main'),
      onAddMedia: options.onAddMedia,
      dialogs: {
        document: this.content.documentDialog,
        image:    this.content.imageDialog,
        video:    this.content.videoDialog,
        audio:    this.content.audioDialog,
        live:     this.content.liveStreamDialog
      }
    });

  }
}

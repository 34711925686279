import _     from 'lodash';
import Utils from '../utils';


const views = {
  fileItem: _.template(`\
<div class="file alert alert-info mb-1" data-id="<%- id %>">
  <div class="col1"><%- name %></div>
  <div class="col2"><%- Math.round(size / 1024) %>kiB</div>
  <div class="col3">
    <span class="remove">&#x2717;</span>
    <span class="tick">&#x2713;</span>
  </div>
  <progress value="0" />
  <div class="processing">
    Processing.. <div class="spinner-grow spinner-grow-sm" role="status"><span class="sr-only">Loading...</span></div>
  </div>
</div>\
`
  ),
  fileInfo: _.template(`\
<div class="info">
  <% _.forEach(items, function(text) { %><%- text %><br/><% }); %>
</div>\
`
  )
};


export default class Uploader {

  constructor(opts) {
    this.el             = opts.el;
    this.dropArea       = this.el.find('.drop-area');
    this.fileList       = this.el.find('.files');
    this.btnStart       = this.el.find('button.start');
    this.btnClear       = this.el.find('button.clear');
    this.fileInput      = this.el.find('input[type=file]');
    this.btnSort        = this.el.find('.controls .sort');
    this.extensions     = this.el.data('extensions').toLowerCase().split(',');
    this.maxSize        = this.el.data('max-size');
    this.url            = this.el.data('url');

    this.cbItemUploaded = opts.onItemUploaded || function() {};
    this.cbAllUploaded  = opts.onAllUploaded  || function() {};

    this.files          = [];
    this.idx            = 1;
    this.state          = 'ready';
    this.sortOrder      = 'asc';

    if (!window.File || !window.FileReader || !window.FileList || !window.Blob) {
      this.el.html("<em>Your browser does not support HTML5 file uploads</em>");
      return;
    }

    this.btnSort.on('click',    e => this.onSort(e));
    this.dropArea.on('dragover dragenter dragleave', (e) => this.onDrag(e));
    this.dropArea.on('drop',    e => this.onDrop(e));
    this.btnStart.on('click',   e => this.onStart(e));
    this.btnClear.on('click',   e => this.onClear(e));
    this.fileInput.on('change', e => this.onSelectFiles(e));
  }

  onDrag(e) {
    e.preventDefault();

    switch (e.type) {
      case 'dragenter':
        this.dropArea.addClass('hover');
        break;
      case 'dragleave':
        this.dropArea.removeClass('hover');
        break;
    }
  }

  onDrop(e) {
    e.preventDefault();
    this.dropArea.removeClass('hover');

    _.each(e.originalEvent.dataTransfer.files, (file) => this.addFile(file));
  }

  onClear() {
    if (this.state !== 'ready') { return; }

    this.files = [];
    this.fileList.empty();
    this.idx = 1;
  }

  onSelectFiles() {
    _.each(this.fileInput[0].files, (file) => this.addFile(file));
  }

  onSort(e) {
    e.preventDefault();
    if (this.state != 'ready') {
      return;
    }

    this.files = _.sortBy(this.files, f => f.name.toLocaleLowerCase());

    if (this.sortOrder === 'desc') {
      this.files.reverse();
    }

    this.fileList.children().each(function(i, li) {
      if (li.classList.length === 0) {
        return $(li).remove();
      }
    });

    for (let file of this.files) {
      this.fileList.append(views.fileItem(file));
    }

    this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
  }

  onStart() {
    if (this.state !== 'ready') {
      return;
    }
    this.state = 'uploading';

    let total  = 0;
    let errors = 0;

    var iterate = () => {
      const file = this.files.shift();

      if (file) {
        total++;
        this.uploadFile(file, iterate)
        .fail(() => errors++);
      } else {
        // finished
        this.state = 'ready';
        this.cbAllUploaded({ total, errors });
      }
    };

    iterate();
  }

  addFile(file) {
    const extension = _.last(file.name.toLowerCase().split('.'));

    if (!this.extensions.includes(extension)) {
      return;
    }

    if (file.size > this.maxSize) {
      return;
    }

    file.id = this.idx++;
    const newNode = $(views.fileItem(file));
    this.fileList.append(newNode);
    this.files.push(file);

    newNode.find('.remove').on('click', e => this.removeFile(e));
  }

  removeFile(e) {
    const node = $(e.target).parents('.file:first');

    _.remove(this.files, f => f.id === node.data('id'));

    node.hide(300, () => node.remove());
  }

  uploadFile(file, cb) {
    const fileEl = this.fileList.children(`.file[data-id=${file.id}]`);
    const fileProgress = fileEl.find('progress');

    fileEl.removeClass('alert-info').addClass('uploading alert-warning');

    return this.ajax(file)
    .done( resp => {
      fileEl.addClass('alert-success done');
      this.cbItemUploaded(resp);
    }).fail(obj => {
      fileEl.addClass('alert-danger failed').append(
        views.fileInfo({ items: obj.errors })
      )
    }).progress(amount => {
      fileProgress.attr('value', amount);
      if (amount === 1) {
        fileEl.addClass('uploaded');
      }
    })
    .always(() => {
      fileEl.removeClass('uploaded alert-info alert-warning');
      _.remove(this.files, file);
      cb();
    });
  }

  ajax(file) {
    const def = new $.Deferred;

    const data = new FormData();
    data.append(Utils.fparam(), Utils.ftoken());
    data.append('file', file, file.name);

    const xhr = new XMLHttpRequest();
    xhr.open('POST', this.url, true);
    xhr.onload = function(e) {
      if (xhr.status === 200) {
        const r = JSON.parse(xhr.responseText);
        if (r.errors) {
          def.reject({errors: r.errors});
        } else {
          def.resolve(r);
        }
      } else {
        def.reject({errors: [xhr.status + ' ' + xhr.responseText.substr(0,100)]});
      }
    };

    xhr.upload.addEventListener('progress', (ev) => { def.notify(ev.loaded/ev.total) }, false);

    xhr.send(data);
    return def;
  }
}

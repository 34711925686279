import ModerationsEditor from './moderations_editor';


// params:
//  $element: Element containing 'discussions' div
export default function DiscussionsEditor(params) {

  this.init = function() {
    this.params = params;

    this.enhance_discussions();
  }

  this.enhance_discussions = function() {
    var self = this;
    this.$discussions = this.params.$element;
    this.$list = this.$discussions.children('.list');

    this.$list.children('.discussion').each(function() {
      self.enhance_discussion($(this));
    });
  }

  this.enhance_discussion = function($discussion) {
    var $controls = $discussion.children('.controls');

    $controls.find('a[data-role=moderate]').click({self:this}, function(e) {
      var self = e.data.self;
      var $a = $(this);

      if($a.data('in_progress')) return false;
      $a.data('in_progress',true);

      new ModerationsEditor({
        url: $a.attr('href'),
        cb_add: function(resp) {
          var $new_discussion = $($.parseHTML($.trim(resp.discussion_code)));
          $discussion.replaceWith($new_discussion);
          self.enhance_discussion($new_discussion);
        },
        cb_after: function() {
          $a.data('in_progress',false);
        }
      });

      return false;
    });
  }

  this.init();
}

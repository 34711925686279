import PerformersManager from './performers_manager';


export default class EventEditor {
  constructor(el) {
    this.el = el;
    this.pm = new PerformersManager(this.el.find('.manage.performers'));

    this.typeSelect     = this.el.find('.type select');
    this.typeInputOther = this.el.find('.type-other input');

    this.el.find('.type select').change(() => {
      this.updateFormState();
    });

    this.updateFormState();
  }

  updateFormState() {
    this.typeInputOther.prop('disabled', this.typeSelect.val() != 'other');
  }
}

var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (_, isFirst, isLast, klass, max, min, pages, range) {
      if (pages.total > 8) {
var min = pages.num - 4
var max = pages.num + 4
}
else {
var min = 1
var max = pages.total
}
var range = _.range(min, max + 1)
pug_html = pug_html + "\u003Cnav\u003E\u003Cul class=\"pagination\"\u003E";
var isFirst = pages.num == 1
var klass = isFirst ? 'disabled' : 'avail'
pug_html = pug_html + "\u003Cli" + (pug.attr("class", pug.classes(["page-item",klass], [false,true]), false, true)) + "\u003E\u003Ca class=\"page-link\" data-page=\"1\" href=\"#\" aria-label=\"First\"\u003E\u003Cspan aria-hidden=\"true\"\u003E\u003Cdiv class=\"fa fa-angle-double-left\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fspan\u003E\u003C\u002Fa\u003E\u003C\u002Fli\u003E\u003Cli" + (pug.attr("class", pug.classes(["page-item",klass], [false,true]), false, true)) + "\u003E\u003Ca" + (" class=\"page-link\""+pug.attr("data-page", (pages.num - 1), true, true)+" href=\"#\" aria-label=\"Previous\"") + "\u003E\u003Cspan aria-hidden=\"true\"\u003E\u003Cdiv class=\"fa fa-angle-left\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fspan\u003E\u003C\u002Fa\u003E\u003C\u002Fli\u003E";
// iterate range
;(function(){
  var $$obj = range;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var i = $$obj[pug_index0];
var isCurrent = pages.num == i
var klass = isCurrent ? 'active' : 'avail'
pug_html = pug_html + "\u003Cli" + (pug.attr("class", pug.classes(["page-item",klass], [false,true]), false, true)) + "\u003E";
if (isCurrent) {
pug_html = pug_html + "\u003Ca" + (" class=\"page-link\""+pug.attr("data-page", i, true, true)+" href=\"#\"") + "\u003E" + (pug.escape(null == (pug_interp = i) ? "" : pug_interp)) + "\u003Cspan class=\"sr-only\"\u003E(current)\u003C\u002Fspan\u003E\u003C\u002Fa\u003E";
}
else {
pug_html = pug_html + "\u003Ca" + (" class=\"page-link\""+pug.attr("data-page", i, true, true)+" href=\"#\"") + "\u003E" + (pug.escape(null == (pug_interp = i) ? "" : pug_interp)) + "\u003C\u002Fa\u003E";
}
pug_html = pug_html + "\u003C\u002Fli\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var i = $$obj[pug_index0];
var isCurrent = pages.num == i
var klass = isCurrent ? 'active' : 'avail'
pug_html = pug_html + "\u003Cli" + (pug.attr("class", pug.classes(["page-item",klass], [false,true]), false, true)) + "\u003E";
if (isCurrent) {
pug_html = pug_html + "\u003Ca" + (" class=\"page-link\""+pug.attr("data-page", i, true, true)+" href=\"#\"") + "\u003E" + (pug.escape(null == (pug_interp = i) ? "" : pug_interp)) + "\u003Cspan class=\"sr-only\"\u003E(current)\u003C\u002Fspan\u003E\u003C\u002Fa\u003E";
}
else {
pug_html = pug_html + "\u003Ca" + (" class=\"page-link\""+pug.attr("data-page", i, true, true)+" href=\"#\"") + "\u003E" + (pug.escape(null == (pug_interp = i) ? "" : pug_interp)) + "\u003C\u002Fa\u003E";
}
pug_html = pug_html + "\u003C\u002Fli\u003E";
    }
  }
}).call(this);

var isLast = pages.num == pages.total
var klass = isLast ? 'disabled' : 'avail'
pug_html = pug_html + "\u003Cli" + (pug.attr("class", pug.classes(["page-item",klass], [false,true]), false, true)) + "\u003E\u003Ca" + (" class=\"page-link\""+pug.attr("data-page", (pages.num + 1), true, true)+" href=\"#\" aria-label=\"Next\"") + "\u003E\u003Cspan aria-hidden=\"true\"\u003E\u003Cdiv class=\"fa fa-angle-right\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fspan\u003E\u003C\u002Fa\u003E\u003C\u002Fli\u003E\u003Cli" + (pug.attr("class", pug.classes(["page-item",klass], [false,true]), false, true)) + "\u003E\u003Ca" + (" class=\"page-link\""+pug.attr("data-page", pages.total, true, true)+" href=\"#\" aria-label=\"Last\"") + "\u003E\u003Cspan aria-hidden=\"true\"\u003E\u003Cdiv class=\"fa fa-angle-double-right\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fspan\u003E\u003C\u002Fa\u003E\u003C\u002Fli\u003E\u003C\u002Ful\u003E\u003C\u002Fnav\u003E";
    }.call(this, "_" in locals_for_with ?
        locals_for_with._ :
        typeof _ !== 'undefined' ? _ : undefined, "isFirst" in locals_for_with ?
        locals_for_with.isFirst :
        typeof isFirst !== 'undefined' ? isFirst : undefined, "isLast" in locals_for_with ?
        locals_for_with.isLast :
        typeof isLast !== 'undefined' ? isLast : undefined, "klass" in locals_for_with ?
        locals_for_with.klass :
        typeof klass !== 'undefined' ? klass : undefined, "max" in locals_for_with ?
        locals_for_with.max :
        typeof max !== 'undefined' ? max : undefined, "min" in locals_for_with ?
        locals_for_with.min :
        typeof min !== 'undefined' ? min : undefined, "pages" in locals_for_with ?
        locals_for_with.pages :
        typeof pages !== 'undefined' ? pages : undefined, "range" in locals_for_with ?
        locals_for_with.range :
        typeof range !== 'undefined' ? range : undefined));
    ;;return pug_html;};
module.exports = template;
import _             from 'lodash';
import BaseComponent from './_base_component';
import Bloodhound    from '../lib/typeahead.bundle';


export default class PerformersManager extends BaseComponent {
  constructor(el) {
    super(...arguments);
    this.el = el;

    const performers = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace('value'),
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      remote: {
        url: '/performers?q=%QUERY',
        cache: false,
        wildcard: '%QUERY',
        transform(resp) { return resp.items; }
      }
    });

    this.roles     = this.el.data('roles').split(',');

    this.items     = this.el.find('.items');
    this.newInput  = this.el.find('.new.performer input[type=text]');

    this.newInput.on('typeahead:change',    () => { this.onNewFormChanged(); });
    this.newInput.on('change',              () => { this.onNewFormChanged(); });
    this.items.on('click', 'button.delete',  e => { this.onPressDel($(e.currentTarget)); });

    this.newInput.typeahead({
        minLength: 2
      }, {
        source: performers,
        name: "main",
        display: 'name'
    }).on('typeahead:select typeahead:autocompleted', (ev, selection) => {
      this.selection = selection;
      this.onNewFormChanged();
    });
  }

  onNewFormChanged() {
    if (!this.selection || (this.newInput.val() !== this.selection.name)) {
      this.selection = null;
      this.newInput.typeahead('val', '');
    }

    if (this.selection) {
      this.add();
    }
  }

  add() {
    this.items.append(this.view('performers_manager/_item', { item: this.selection, roles: this.roles }));

    this.newInput.typeahead('val', '');
    this.selection = null;
    this.onNewFormChanged();
  }

  onPressDel(btn) {
    btn.closest('tr').remove();
  }
}

export default function GroupMessageEditor(params) {
  var $element;
  var $sms;
  var $title;
  var $contents;
  var $length;

  function initialize() {
    $element = params.$element;
    $sms = $element.find('.form-group.sms input[type=checkbox]:first');
    $balance = $element.find('.balance');
    $title = $element.find('.form-group.title input[type=text]:first');
    $contents = $element.find('.form-group.contents textarea:first');
    $length = $element.find('.length');

    // Toggle sms functionality based on initial sms checkbox state
    toggle_sms_functionality($sms.is(':checked'));

    // Hide title field & hide sms count if sms box is unticked
    $sms.change(function() {
      toggle_sms_functionality($sms.is(':checked'));
    });

    get_balance();
  }

  // state: boolean, enable or disable
  function toggle_sms_functionality(state) {
    if(state) {
      $title.parents('.form-group:first').addClass('hidden');
      $length.removeClass('hidden');
      $contents.on('click mouseup blur keyup input', limit_characters);

      // update before anything is typed
      limit_characters({});
    } else {
      $title.parents('.form-group:first').removeClass('hidden');
      $length.addClass('hidden');
      $contents.off('click mouseup blur keyup input', limit_characters);
    }
  }

  // this executes only once per page load
  function get_balance() {
    var $spinner = $balance.find('.spinner');

    $.get($balance.data('url'))
    .then(function (resp) {
      $balance.append(resp.balance);
    })
    .catch(function (err) {
      $balance.append('<span class="text-danger">fetching balance failed</span>');
    })
    .always(function () {
      $spinner.addClass('hidden');
    });
  }

  // limit amount of user input
  function limit_characters(event) {
    var count, smses, smsg, text;
    var ret = true;
    var max_single_sms = 160;
    var sms_split_size = 153
    var max_smses = 2;
    var max_sms_chars = sms_split_size*max_smses;

    text = $contents.val().replace(/\r/g,'');
    count = text.length;

    $length.removeClass('toolong');

    if(count==0) smses=0;
    else if(count<=max_single_sms) smses = 1;
    else smses = Math.ceil(count/sms_split_size);

    if(smses == 0) smsg = "smses";
    else if(smses == 1) smsg = "sms";
    else if(smses <= max_smses) smsg = "smses";
    else {
      smsg = "smses, maximum length (" + max_sms_chars + ") exceeded.";
      $length.addClass('toolong');
      if($.inArray(event.which,[8,37,38,39,40,46]) == -1) {
        ret = false;
      }
      $contents.val(text.substr(0,max_sms_chars));
    }
    if(ret) {
      smsg = (max_sms_chars - count) + " remaining, " + smses + " " + smsg;
    } else {
      smsg = "0 remaining, stop typing!";
    }
    $length.html(smsg);

    return ret;
  }

  initialize();
}

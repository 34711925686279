import _     from 'lodash';
import Utils from '../utils';


const SPINNER_HTML = '<div class="text-center"><div class="spinner spinner-border" role="status"><span class="sr-only">Loading...</span></div></div>';

export default class InsertDialog {
  static type = ''; // set this in derived classes
  static multiSelect = false;

  constructor(opts) {
    this.open = this.open.bind(this);
    this.dialog = $(opts.html).appendTo('body');
    this.body = this.dialog.find('.modal-body');
    this.url = this.dialog.data('url');

    this.dialog.find('button.insert').click(e => {
      e.preventDefault();
      if (this.getAndInsertItems()) {
        $(e.target).find('.spinner').show();
      } else {
        this.dialog.modal("hide");
      }
    });
  }

  enhanceItems() {
    this.items = this.dialog.find('.items li');

    this.items.each((index, item) => {
      item = $(item);

      item.click(e => {
        e.preventDefault();
        if (!this.constructor.multiSelect) { item.siblings().removeClass('selected'); }
        item.toggleClass('selected');
      });
    });
  }

  // load pages
  enablePagination() {
    this.dialog.find('.pagination li a').click(e => {
      e.preventDefault();
      this.body.html(SPINNER_HTML);

      this.loadPage($(e.currentTarget).attr('href'));
    });
  }

  deselectAllItems() {
    this.dialog.find('li.selected').removeClass('selected');
  }

  loadPage(page) {
    const matches = page.toString().match(/page\=(\d+)/);

    if (_.get(matches, 'length') > 1) {
      page = matches[1];
    }

    return $.get(
      `${this.dialog.data('list-url')}`,
      {page}
    ).done((data, ts, xhr) => {
      this.body.html(data.code);
      this.enhanceItems();
      this.enablePagination();
    });
  }

  insertTags(resp) {
    if (resp.data) {
      this.dialog.modal('hide');
      this.callback(resp.data);
    } else {
      this.dialog.find('.errors').hide('fast').text(resp.error).show('fast');
    }
  }

  getAndInsertItems() {
    const ids = [];

    for (let item of this.items.filter('.selected')) {
      ids.push($(item).find("input[name='id']").val());
    }

    if (ids.length > 0) {
      const ret = {};
      const pdata = {ids};
      pdata[Utils.fparam()] = Utils.ftoken();

      $.ajax({
        type: 'POST',
        url: this.dialog.data('tags-url'),
        dataType: 'json',
        data: pdata
      }).done( (data, textStatus, xhr) => {
        ret['data'] = data;
        this.insertTags(ret);
        this.deselectAllItems();
        this.dialog.parent().find('.ui-dialog-buttonpane button .spinner').remove();
      }).fail( (xhr, textStatus, errorThrown) => {
        ret['error'] = textStatus + ', ' + errorThrown;
        this.insertTags(ret);
      });
      return true;
    } else {
      return false;
    }
  }

  open(callback) {
    this.callback = callback;
    this.dialog.find('.modal-footer button .spinner').hide();
    this.dialog.modal('show');
    this.loadPage(1);
  }
}

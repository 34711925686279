import _        from 'lodash';
import settings from '../settings';

let count = 0;

export default class TinymceEditor {
  constructor(options) {
    this.el         = options.el;
    this.target     = this.el.find('textarea.text');
    this.loader     = this.el.find('.spinner');
    this.dialogs    = options.dialogs;
    this.onAddMedia = options.onAddMedia || (function() {});
    this.count = count++;

    this.setupTinyMce();
  }

  loadTinymceModule() {
    this.loader.removeClass('hidden');

    return import(/* webpackChunkName: 'tinymce' */ './_tinymce_deps')
    .then(({ default: tinymce }) => tinymce)
    .catch((err) => {
      this.el.prepend(`<div class="error">Loading TinyMCE failed; ${err}</div>`);
    })
    .finally(() => {
      this.loader.addClass('hidden');
    });
  }

  setupTinyMce() {
    this.settings = _.cloneDeep(settings['tinymce']);
    this.settings.setup = (editor) => this.addButtons(editor);
    this.settings.target = this.target.get(0);

    this.loadTinymceModule().then((tinymce) => {
      tinymce.init(this.settings);
    });
  }

  addButtons(editor) {
    this.addDocumentButton(editor);
    this.addImageButton(editor);
    this.addVideoButton(editor);
    this.addAudioRecordingButton(editor);
    this.addLiveStreamButton(editor);
  }

  addDocumentButton(ed) {
    ed.ui.registry.addIcon('document', '<svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 450 450"><title>pdf</title><path d="M84,425.5V20.5H249.28C272.39,41.34,348.79,112.84,367,130.63V425.5Z" fill="#fff"/><path d="M243.49,35.5C268.34,58.15,329.88,115.72,352,137V410.5H99V35.5H243.49M255,5.5H81a12,12,0,0,0-12,12v411a12,12,0,0,0,12,12H370a12,12,0,0,0,12-12v-304c-5-6-111-105-127-119Z"/><polygon points="369 131.5 249 131.5 249 17.5 309 75.5 369 131.5" stroke="#000" stroke-miterlimit="10" stroke-width="10"/><path d="M376,334.5H77v-130H376Z" stroke="#000" stroke-miterlimit="10" stroke-width="10"/><path d="M144.13,232.74a125.61,125.61,0,0,1,20.66-1.4c9.43,0,16.15,1.8,20.66,5.41a19.78,19.78,0,0,1,7.22,15.65c0,6.62-2.21,12.23-6.22,16-5.21,4.92-12.93,7.12-22,7.12a38.54,38.54,0,0,1-5.21-.3v24.17H144.13Zm15.15,30.69a23.4,23.4,0,0,0,5.11.4c8.12,0,13.14-4.11,13.14-11,0-6.22-4.31-9.93-11.94-9.93a26.07,26.07,0,0,0-6.31.6Z" fill="#fff"/><path d="M202.39,232.74a136.9,136.9,0,0,1,20.66-1.4c12.84,0,21.16,2.31,27.68,7.22,7,5.21,11.43,13.54,11.43,25.47,0,12.94-4.71,21.86-11.23,27.38-7.12,5.91-18,8.72-31.19,8.72a132.76,132.76,0,0,1-17.35-1Zm15.35,55.16a28.16,28.16,0,0,0,5.31.3c13.84.1,22.87-7.52,22.87-23.67.1-14-8.13-21.46-21.26-21.46a31.84,31.84,0,0,0-6.92.6Z" fill="#fff"/><path d="M272.18,231.84H313.5v12.54h-26v15.44h24.26v12.43H287.53v27.18H272.18Z" fill="#fff"/></svg>');
    ed.ui.registry.addButton('adddocument', {
      tooltip: 'Add PDF document',
      icon: 'document',
      onAction: () => {
        this.dialogs.document.open(false, data => {
          ed.focus();
          ed.insertContent(data.code);
        });
      }
    }
    );
  }

  addImageButton(ed) {
    ed.ui.registry.addIcon('photo-camera', '<svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 253 177.94"><title>camera</title><path d="M279,255H27V111H279ZM197.5,111l-22-32.94h-45L107.5,111M72,102H45v9H72Zm189,0H234v9h27ZM153,138a45,45,0,1,0,45,45A45,45,0,0,0,153,138Z" transform="translate(-26.5 -77.56)" stroke="#000"/></svg>');
    ed.ui.registry.addButton('addimages', {
      tooltip: 'Add image',
      icon: 'photo-camera',
      onAction: () => {
        this.dialogs.image.open(false, data => {
          ed.focus();
          ed.insertContent(data.code);
        });
      }
    }
    );
  }

  addVideoButton(ed) {
    ed.ui.registry.addIcon('video-camera', '<svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 224.11 262.07"><title>video_camera</title><line x1="83.78" y1="152.8" x2="16.03" y2="255.93" fill="none" stroke="#000" stroke-width="12"/><path d="M124.15,65.59c0,24.34-19.3,44.07-43.11,44.07s-43.1-19.73-43.1-44.07S57.24,21.51,81,21.51,124.15,41.24,124.15,65.59Z" transform="translate(-37.44 -21.01)" stroke="#000"/><path d="M209,65.43c0,24.15-19.15,43.74-42.77,43.74s-42.78-19.59-42.78-43.74,19.15-43.74,42.78-43.74S209,41.27,209,65.43Z" transform="translate(-37.44 -21.01)" stroke="#000"/><line x1="88.68" y1="139.85" x2="88.68" y2="262.07" fill="none" stroke="#000" stroke-width="12"/><line x1="93.75" y1="152.8" x2="161.5" y2="255.93" fill="none" stroke="#000" stroke-width="12"/><polygon points="223.62 67.78 223.62 175.39 151.87 139.52 151.87 103.65 223.62 67.78"/><line x1="151.87" y1="103.65" x2="223.61" y2="67.78" fill="none" stroke="#000"/><line x1="151.87" y1="139.52" x2="223.61" y2="175.39" fill="none" stroke="#000"/><line x1="223.61" y1="67.78" x2="223.61" y2="175.39" fill="none" stroke="#000"/><line x1="151.87" y1="103.65" x2="151.87" y2="139.52" fill="none" stroke="#000"/><path d="M189.51,179.41H57.27V107.86H189.51Z" transform="translate(-37.44 -21.01)"/></svg>');
    ed.ui.registry.addButton('addvideos', {
      tooltip: 'Add video',
      icon: 'video-camera',
      onAction: () => {
        this.dialogs.video.open(data => {
          ed.focus();
          ed.insertContent(data.code);

          this.onAddMedia('video');
        });
      }
    }
    );
  }

  addAudioRecordingButton(ed) {
    ed.ui.registry.addIcon('speaker', '<svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 238.24 233.9"><title>speaker</title><path d="M68.86,200.84H24.93V111H68.86Z" transform="translate(-24.43 -39.05)" stroke="#000"/><path d="M217.34,55.44c55,55,54.36,144.6-1.4,200.35m-23.12-25.45c40.79-40.79,39.75-107.84-2.33-149.91M165.27,206.52c28.4-28.39,28.81-73.93.93-101.81m-23.81,76.13c13.94-13.94,13.52-36.92-.94-51.38" transform="translate(-24.43 -39.05)" fill="none" stroke="#000" stroke-width="9"/><polygon points="92.07 233.9 19.75 149.5 19.85 86.63 91.86 0 100.72 0 100.72 233.9 92.07 233.9"/><path d="M-81,227.44" transform="translate(-24.43 -39.05)"/></svg>');
    ed.ui.registry.addButton('addaudiorecordings', {
      tooltip: 'Add audio recording',
      icon: 'speaker',
      onAction : () => {
        this.dialogs.audio.open(data => {
          ed.focus();
          ed.insertContent(data.code);

          this.onAddMedia('audio');
        });
      }
    }
    );
  }

  addLiveStreamButton(ed) {
    // disabled
    ed.ui.registry.addButton('addlivestream', {
      tooltip: 'Add live stream',
      //image: require('images/simple/live20.png'),
      onAction : () => {
        this.dialogs.live.open(data => {
          ed.focus();
          ed.insertContent(data.code);

          this.onAddMedia('livestream');
        });
      }
    }
    );
  }
}

var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (data, eventId, moment, tm, view) {
      if (data.items.length > 0) {
pug_html = pug_html + "\u003Ctable class=\"table table-striped\"\u003E";
// iterate data.items
;(function(){
  var $$obj = data.items;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var item = $$obj[pug_index0];
var instance = item.instance
var associatedToOtherEvent = instance.event_id && instance.event_id != eventId
var trClass = associatedToOtherEvent ? 'taken' : ''
pug_html = pug_html + "\u003Ctr" + (pug.attr("class", pug.classes([trClass], [true]), false, true)) + "\u003E\u003Ctd\u003E";
tm = { audio_recording: 'fa-microphone', content: 'fa-file-text-o', photo_album: 'fa-camera', video: 'fa-video-camera' }
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["fa",tm[instance.model_type]], [false,true]), false, true)) + "\u003E\u003C\u002Fdiv\u003E\u003C\u002Ftd\u003E\u003Ctd class=\"dont-break-word\"\u003E" + (pug.escape(null == (pug_interp = moment(item.date).format('YYYY-MM-DD')) ? "" : pug_interp)) + "\u003C\u002Ftd\u003E\u003Ctd\u003E\u003Ca" + (pug.attr("href", item.url, true, true)) + "\u003E";
switch (instance.model_type){
case 'audio_recording':
pug_html = pug_html + ((pug.escape(null == (pug_interp = instance.speaker) ? "" : pug_interp)) + " - " + (pug.escape(null == (pug_interp = instance.title) ? "" : pug_interp)));
  break;
case 'content':
pug_html = pug_html + (pug.escape(null == (pug_interp = instance.all_locale_title) ? "" : pug_interp));
  break;
case 'photo_album':
pug_html = pug_html + (pug.escape(null == (pug_interp = instance.name) ? "" : pug_interp));
  break;
case 'video':
pug_html = pug_html + (pug.escape(null == (pug_interp = instance.title) ? "" : pug_interp));
  break;
}
pug_html = pug_html + "\u003C\u002Fa\u003E\u003C\u002Ftd\u003E\u003Ctd\u003E";
if (instance.event_id == eventId) {
pug_html = pug_html + "\u003Cbutton" + (" class=\"associate btn btn-success\""+" type=\"button\""+pug.attr("data-id", instance.id, true, true)+pug.attr("data-model-type", instance.model_type, true, true)) + "\u003E\u003Cdiv class=\"fa fa-link\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fbutton\u003E";
}
else
if (associatedToOtherEvent) {
}
else {
pug_html = pug_html + "\u003Cbutton" + (" class=\"associate btn btn-default\""+" type=\"button\""+pug.attr("data-id", instance.id, true, true)+pug.attr("data-model-type", instance.model_type, true, true)) + "\u003E\u003Cdiv class=\"fa fa-link\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fbutton\u003E";
}
pug_html = pug_html + "\u003C\u002Ftd\u003E\u003C\u002Ftr\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var item = $$obj[pug_index0];
var instance = item.instance
var associatedToOtherEvent = instance.event_id && instance.event_id != eventId
var trClass = associatedToOtherEvent ? 'taken' : ''
pug_html = pug_html + "\u003Ctr" + (pug.attr("class", pug.classes([trClass], [true]), false, true)) + "\u003E\u003Ctd\u003E";
tm = { audio_recording: 'fa-microphone', content: 'fa-file-text-o', photo_album: 'fa-camera', video: 'fa-video-camera' }
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["fa",tm[instance.model_type]], [false,true]), false, true)) + "\u003E\u003C\u002Fdiv\u003E\u003C\u002Ftd\u003E\u003Ctd class=\"dont-break-word\"\u003E" + (pug.escape(null == (pug_interp = moment(item.date).format('YYYY-MM-DD')) ? "" : pug_interp)) + "\u003C\u002Ftd\u003E\u003Ctd\u003E\u003Ca" + (pug.attr("href", item.url, true, true)) + "\u003E";
switch (instance.model_type){
case 'audio_recording':
pug_html = pug_html + ((pug.escape(null == (pug_interp = instance.speaker) ? "" : pug_interp)) + " - " + (pug.escape(null == (pug_interp = instance.title) ? "" : pug_interp)));
  break;
case 'content':
pug_html = pug_html + (pug.escape(null == (pug_interp = instance.all_locale_title) ? "" : pug_interp));
  break;
case 'photo_album':
pug_html = pug_html + (pug.escape(null == (pug_interp = instance.name) ? "" : pug_interp));
  break;
case 'video':
pug_html = pug_html + (pug.escape(null == (pug_interp = instance.title) ? "" : pug_interp));
  break;
}
pug_html = pug_html + "\u003C\u002Fa\u003E\u003C\u002Ftd\u003E\u003Ctd\u003E";
if (instance.event_id == eventId) {
pug_html = pug_html + "\u003Cbutton" + (" class=\"associate btn btn-success\""+" type=\"button\""+pug.attr("data-id", instance.id, true, true)+pug.attr("data-model-type", instance.model_type, true, true)) + "\u003E\u003Cdiv class=\"fa fa-link\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fbutton\u003E";
}
else
if (associatedToOtherEvent) {
}
else {
pug_html = pug_html + "\u003Cbutton" + (" class=\"associate btn btn-default\""+" type=\"button\""+pug.attr("data-id", instance.id, true, true)+pug.attr("data-model-type", instance.model_type, true, true)) + "\u003E\u003Cdiv class=\"fa fa-link\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fbutton\u003E";
}
pug_html = pug_html + "\u003C\u002Ftd\u003E\u003C\u002Ftr\u003E";
    }
  }
}).call(this);

pug_html = pug_html + ("\u003C\u002Ftable\u003E" + (null == (pug_interp = view('shared/_pagination', { pages: data.pages })) ? "" : pug_interp));
}
else {
pug_html = pug_html + "\u003Cdiv class=\"alert alert-warning heading-text small\"\u003E\u003Ci class=\"fa fa-info-circle\"\u003E\u003C\u002Fi\u003E No items can be associated. Items (videos, audio, ..) only appear here if their date is the same as this event, or later.\u003C\u002Fdiv\u003E";
}
    }.call(this, "data" in locals_for_with ?
        locals_for_with.data :
        typeof data !== 'undefined' ? data : undefined, "eventId" in locals_for_with ?
        locals_for_with.eventId :
        typeof eventId !== 'undefined' ? eventId : undefined, "moment" in locals_for_with ?
        locals_for_with.moment :
        typeof moment !== 'undefined' ? moment : undefined, "tm" in locals_for_with ?
        locals_for_with.tm :
        typeof tm !== 'undefined' ? tm : undefined, "view" in locals_for_with ?
        locals_for_with.view :
        typeof view !== 'undefined' ? view : undefined));
    ;;return pug_html;};
module.exports = template;
var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (item, roles) {
      pug_html = pug_html + "\u003Ctr class=\"new\"\u003E\u003Ctd\u003E\u003Cinput" + (" type=\"hidden\" name=\"item[performers][][profile_id]\""+pug.attr("value", item.id, true, true)) + "\u003E\u003Cselect class=\"role\" name=\"item[performers][][role]\"\u003E";
// iterate roles
;(function(){
  var $$obj = roles;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var role = $$obj[pug_index0];
pug_html = pug_html + "\u003Coption" + (pug.attr("value", role, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = role) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var role = $$obj[pug_index0];
pug_html = pug_html + "\u003Coption" + (pug.attr("value", role, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = role) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fselect\u003E\u003C\u002Ftd\u003E\u003Ctd\u003E" + (pug.escape(null == (pug_interp = item.name) ? "" : pug_interp)) + "\u003C\u002Ftd\u003E\u003Ctd\u003E\u003Cbutton class=\"delete btn btn-danger btn-sm\" type=\"button\"\u003Ex\u003C\u002Fbutton\u003E\u003C\u002Ftd\u003E\u003C\u002Ftr\u003E";
    }.call(this, "item" in locals_for_with ?
        locals_for_with.item :
        typeof item !== 'undefined' ? item : undefined, "roles" in locals_for_with ?
        locals_for_with.roles :
        typeof roles !== 'undefined' ? roles : undefined));
    ;;return pug_html;};
module.exports = template;
import CommentsStateEditor from './comments/state_editor';
import ModerationsEditor   from './moderations_editor';
import Utils               from '../utils';


// params:
//  $element: Element containing 'comments' div
export default class CommentsEditor {

  constructor(params) {
    this.parent = params.$element;

    this.enhanceComments();
  }

  enhanceComments() {
    this.comments = this.parent.find('.comments:first');
    this.view     = this.comments.data('view');
    this.newCode  = this.comments.children('.new_code').html();
    this.state    = this.comments.data('state');

    this.stateEditor = new CommentsStateEditor({ parent: this });

    if (this.state !== 'enabled') {
      this.comments.find('a[data-level=user]').remove();
    }

    this.comments.children('.comment').each((i, el) => {
      this.enhanceComment($(el));
    });

    if (this.view === 'threaded') {
      // show reply box for first root comment
      this.comments.children('.comment:first').children('.main').children('.controls').find('a[data-role=reply]').click();
    } else if (this.view === 'flat') {
      this.newReply = this.comments.children('.new_comment');
      this.enhanceReply(undefined, this.newReply);
    }
  }

  enhanceComment(comment) {
    this.enhanceCommentMain(comment);

    // recurse through replies
    comment.children('.replies').children('.comment').each(el => {
      this.enhanceComment($(el));
    });
  }

  enhanceCommentMain(comment) {
    const main     = comment.children('.main');
    const controls = main.children('.controls');

    // new reply
    controls.find('a[data-role=reply]').click(e => {
      e.preventDefault();
      const a       = $(e.currentTarget);
      comment = a.parents('.comment:first');
      const replies = $comment.children('.replies');

      if (replies.children('.new_comment').length > 0) {
        this.cancelReply(comment, replies.children('.new_comment'));
      } else {
        const reply = $($.parseHTML($.trim(this.newCode)));
        reply.prependTo(replies);
        this.enhanceReply(comment, reply);
      }

    });

    // edit existing reply
    controls.find('a[data-role=edit]').click(e => {
      e.preventDefault();
      const a       = $(e.currentTarget);
      comment = a.parents('.comment:first');
      const text    = main.children('.text');

      if (a.data('in_progress')) { return false; }

      if (main.children('.edit_comment').length > 0) {
        this.cancelReply(comment, main.children('.edit_comment'));
      } else {
        a.data('in_progress', true);
        Utils.get({
          url: a.attr('href'),
          cb_success: resp => {
            const edit = $($.parseHTML($.trim(resp.code)));

            text.hide();
            edit.insertAfter(text);
            this.enhanceReply(comment, edit);
          },
          cb_after: () => {
            a.data('in_progress', false);
          }
        });
      }

    });

    // moderate comment
    controls.find('a[data-role=moderate]').click(e => {
      e.preventDefault();
      const a = $(e.currentTarget);

      if (a.data('in_progress')) {
        return;
      }

      a.data('in_progress', true);

      new ModerationsEditor({
        url: a.attr('href'),
        cbAdd: resp => {
          const newMain = $($.parseHTML($.trim(resp.comment_main_code)));
          main.replaceWith(newMain);
          this.enhanceCommentMain(comment);
        },
        cbAfter() {
          a.data('in_progress', false);
        }
      });
    });

    // delete comment
    controls.find('a[data-role=delete]').click(e => {
      e.preventDefault();
      const a = $(e.currentTarget);
      comment = a.parents('.comment:first');

      if (a.data('in_progress')) {
        return false;
      }

      if (confirm(a.data('confirm'))) {
        a.data('in_progress', true);

        Utils.del({
          url: a.attr('href'),
          cb_success: resp => {
            if (resp.errors) {
              alert(resp.errors.join(', '));
            } else {
              const newMain = $($.parseHTML($.trim(resp.main_code)));
              main.replaceWith(newMain);
              this.enhanceCommentMain(newMain);
            }
          },
          cb_after: () => {
            a.data('in_progress', false);
          }
        });
      }
    });
  }

  enhanceReply(comment, reply) {
    reply.find('button[type=reset]').click(e => {
      e.preventDefault();
      this.cancelReply(comment, reply);
    });

    if ((reply.data('action') === 'edit') || (this.view === 'threaded')) {
      reply.find('form:first textarea:first').focus();
    }

    reply.find('form:first').submit(e => {
      e.preventDefault();
      const form = $(e.currentTarget);

      this.submitReply(comment, reply, form);
    });
  }

  cancelReply(comment, reply) {
    reply.remove();

    if (reply.data('action') === 'edit') {
      comment.children('.text').show();
    }
  }

  submitReply(comment, reply, form) {
    const action = reply.data('action');

    if (reply.data('in_progress')) { return false; }

    if ((action === 'new') && (this.view === 'threaded') && (comment.length > 0)) {
      form.find('input[name="comment[parent_comment_id]"]').val(comment.data('id'));
    }

    this.showSubmitSpinner(reply);
    reply.data('in_progress', true);

    return $.ajax({
      type     : form.attr('method'),
      url      : form.attr('action'),
      dataType : 'json',
      data     : (form.serialize() + '&pustak=rs4fy7jya')
    }).fail((xhr, status, error) => {
      alert(`Error ${xhr.status}: ${xhr.statusText}`);
    }).done(resp => {
      reply.find('.errors').remove();

      form.find('.form-control').removeClass('is-invalid');

      if (resp.errors) {
        $($.parseHTML($.trim(resp.code))).prependTo(form);
        resp.errors.forEach(error => {
          form.find(`.form-control[name$='${error.attribute}]']`).addClass('is-invalid');
        });
      } else {
        if (reply.data('action') === 'edit') {
          const newMain = $($.parseHTML($.trim(resp.main_code)));
          comment.children('.main').replaceWith(newMain);
          this.enhanceCommentMain(comment);
        } else {
          const newComment = $($.parseHTML($.trim(resp.code)));

          if (this.view === 'threaded') {
            reply.replaceWith(newComment);
          } else if (this.view === 'flat') {
            this.newReply = $($.parseHTML($.trim(this.newCode)));

            newComment.insertBefore(reply);
            reply.replaceWith(this.newReply);

            this.enhanceReply(undefined, this.newReply);
          }

          this.enhanceComment(newComment);
        }
      }

    }).always((request, status) => {
      this.removeSubmitSpinner(reply);
      reply.data('in_progress', false);
    });
  }

  showSubmitSpinner(reply) {
    reply.find('button .spinner').removeClass('hidden');
  }

  removeSubmitSpinner(reply) {
    reply.find('button .spinner').addClass('hidden');
  }
}

import _                        from 'lodash';
import AudioDialog              from './audio_dialog';
import ContentTranslationEditor from './content_translation_editor';
import { LiveStreamDialog }     from './live';
import UploadedFileDialog       from './uploaded_file_dialog';
import VideoDialog              from './video_dialog';


export default class ContentEditor {
  constructor(el) {
    this.el = el;
    this.data                  = JSON.parse(this.el.find(' > .invisible .data').text());
    this.tabsContainer         = this.el.find('.tabs');
    this.translationsContainer = this.el.find('.translations-container');
    this.addTab                = this.tabsContainer.find('.add-item');
    this.flagsControl          = this.el.find('.flags-control');
    this.contentFlagsField     = this.el.find('input.flags');
    this.abstractImageField    = this.el.find('input.abstract-image');
    this.abstractImage         = this.el.find('.abstract-image-control');

    this.translationEditors = [];
    this.newId              = 1;

    this.setupDocumentDialog();
    this.setupImageDialog();
    this.setupVideoDialog();
    this.setupAudioDialog();
    this.setupLiveStreamDialog();

    const tabs         = this.tabsContainer.children('.tab');
    const translations = this.translationsContainer.children('.translation');

    for (let index = 0; index < tabs.length; index++) {
      const tab = tabs[index];
      this.setupContentTranslation($(tab), $(translations[index]));
    }

    this.translationEditors[0].activate();

    this.addTab.click(e => {
      e.preventDefault();
      this.addTranslation();
    });

    this.flagsControl.find('.cf').click(e => {
      e.preventDefault();
      const target = $(e.target);
      this.toggleContentFlag(target.data('type'));
    });
  }

  setupContentTranslation(tabEl, translationEl) {
    this.translationEditors.push(
      new ContentTranslationEditor({
        content: this,
        tab: tabEl,
        translation: translationEl,
        languages: this.data.languages,
        onAddMedia: type => {
          this.toggleContentFlag(type, true);

        }})
    );
    return _.last(this.translationEditors);
  }

  setupDocumentDialog() {
    this.documentDialog = new UploadedFileDialog({html: this.data.addDocumentsModal});
  }

  setupImageDialog() {
    this.imageDialog = new UploadedFileDialog({html: this.data.addImagesModal});

    this.abstractImage.click(e => {
      e.preventDefault();
      this.imageDialog.open(true, data => {
        this.abstractImage.html(data.code);
        this.abstractImageField.val(data.ids[0]);
      });
    });
  }

  setupVideoDialog() {
    this.videoDialog = new VideoDialog({html: this.data.addVideosModal});
  }

  setupAudioDialog() {
    this.audioDialog = new AudioDialog({html: this.data.addAudiosModal});
  }

  setupLiveStreamDialog() {
    this.liveStreamDialog = new LiveStreamDialog();
  }

  // flag: ['audio'.'video','photos']
  // val: if provided, specifies if flag should be set or unset
  toggleContentFlag(flag, toggle) {
    flag = this.flagsControl.find(`.cf.${flag}`);

    if (toggle) {
      flag.toggleClass('active', toggle);
    } else {
      flag.toggleClass('active');
    }

    const contentFlags = _.map(
      this.flagsControl.find('.cf.active'),
      flag => $(flag).data('type'));
    this.contentFlagsField.val(contentFlags.join(','));
  }

  newTranslationTab() {
    return $($.parseHTML($.trim(this.data.tabHtml)));
  }

  newTranslationForm() {
    const html = $.trim(this.data.translationForm.replace(/ID_GOES_HERE/g, `new-${this.newId}`));
    this.newId += 1;
    return $($.parseHTML(html));
  }

  addTranslation() {
    const newTab = this.newTranslationTab();
    const translationEl = this.newTranslationForm();

    newTab.insertBefore(this.addTab);
    this.translationsContainer.append(translationEl);

    this.setupContentTranslation(newTab, translationEl).activate();
  }

  removeTranslation(translation) {
    const index = _.indexOf(this.translationEditors, translation);
    this.translationEditors.splice(index, 1);
    if (translation.isActive() && (this.translationEditors.length >= 1)) {
      const nextActiveIndex = index === 0 ? 0 : (index - 1);
      this.translationEditors[nextActiveIndex].activate();
    }
  }
}

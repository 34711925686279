import _ from 'lodash';


const liveStreamTemplates = {
  player(opts) {
    return `\
<div class="econtainer">
  <script type="text/javascript" src="/jwplayer7/jwplayer.js"></script>
  <div class="live-stream-player" data-sources="${opts.sources.player.join('|')}">
    <div class="alert alert-info">
      Loading live player...
      <div class="editor_only">
        Live stream player will load here
      </div>
    </div>
  </div>
  <script type="text/javascript">
  jwplayer.key = '6gl8qmq58sMlL1RxFPQtFrEhyCImImmZMKkGIgRtxKw=';
  </script>

  <br/>
  <p style="text-align: center;">
    iPhone/iPad/Android users <a href="${opts.sources.links.mobile}">click here to watch</a>
  </p>
</div>
<p>&nbsp;</p>\
`;
  },

  modal(opts) {
    return `\
<div class="modal fade" tabindex="-1" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        <div class="modal-title">Live video player</div>
      </div>
      <div class="modal-body text">
        <table class="table">
          <tr>
            <td>Stream to:</td>
            <td><span class="mono">rtmp://www.dandypolish.org.au/live_streams</span></td>
          </tr>
          <tr>
            <td>Name:</td>
            <td><span class="mono">livestream1, livestream2</span></td>
          </tr>
          <tr>
            <td>Username:</td>
            <td><span class="mono">admin</span></td>
          </tr>
          <tr>
            <td>Password:</td>
            <td><span class="mono">ask the webmaster</span></td>
          </tr>
          <tr>
            <td>Bitrate:</td>
            <td>
              <span class="mono">
                I recommend multi-bitrate streaming:<br/>
                1. 300kbit 426x240<br>
                2. 800kbit 900x506
              </span>
            </td>
          </tr>
        </table>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary insert">Insert</button>
        <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>\
`;
  }
};


export class LiveStreamDialog {
  static sources = {
    player: [
      '/live_streams/main_abr/playlist.m3u8',
      '/live_streams/main_abr/manifest.mpd'
    ],
    links: {
      mobile: '/live_streams/main_abr/playlist.m3u8'
    }
  };

  constructor(opts) {
    this.dialog = $(liveStreamTemplates.modal()).appendTo('body');
    this.body = this.dialog.find('.modal-body');

    this.dialog.find('button.insert').click(e => this.onInsert(e));
  }

  onInsert(e) {
    this.callback({code: liveStreamTemplates.player({ sources: this.constructor.sources })});
    this.dialog.modal('hide');
  }

  open(callback) {
    this.callback = callback;
    this.dialog.modal('show');
  }
}


export class LiveStreamPlayer {
  static offlineSource = {
    offlineSource: {
      file: '/media/videos/not_streaming_low.mp4?1', repeat: true
    }
  };

  constructor(el) {
    // @el is replaced once player initializes.
    this.el = el;
    this.elParent = this.el.parent();
    this.sources  = this.el.data('sources').split('|');

    // Player setup must be initalised in a simple way.
    // If we try to initialise it inside `$.ajax.done` then it will fail to play
    // as the html5 player must be initialised during either:
    // a: page load, b: user action.
    this.playOfflineMessage();
    this.manageStream();
  }

  isSourceLive(source, cb) {
    $.ajax({url: this.sources[0]}).done(function(data, textStatus, jqXHR) {
      const isLive = (typeof data === 'string') && (data.indexOf('#EXT-X-STREAM-INF') !== -1);

      cb(isLive);
    }).fail((jqXHR, textStatus, errorThrown) => cb(false));
  }

  setup(opts) {
    if (this.setupComplete) {
      return;
    }

    this.player = jwplayer(this.el[0]).setup({
      sources: opts.playlist,
      width: 650, height: 366, autostart: true, ga: {}
    })
    .on('error', e => this.onError(e))
    .on('idle',  e => this.onIdle(e))
    .on('play',  e => this.onPlay(e));

    this.setupComplete = true;
  }

  onError(e) {
    console.log("live player error", e);

    if (!this.isPlayingOfflineMessage()) {
      setTimeout(() => { this.playOfflineMessage() }, 3000);
    }
  }

  onIdle(e) {
    if ((e.type === 'idle') && (e.reason === 'complete') && this.isPlayingOfflineMessage()) {
      // loop video
      this.playOfflineMessage();
    }
  }

  onPlay(e) {
    // Bug in jwplayer; `repeat: true` doesn't work.
    const shouldLoop = this.isPlayingOfflineMessage();
    this.elParent.find('.jwplayer video').prop('loop', shouldLoop);
  }

  play(playlist) {
    if (this.setupComplete) {
      this.player.load(playlist);
    } else {
      this.setup({ playlist });
    }
  }

  playStream() {
    const playlist = _.map(this.sources, s => ({ file: s }));
    console.log("playing stream", playlist);
    this.play(playlist);
  }

  playOfflineMessage() {
    this.play([this.constructor.offlineSource]);
  }

  isPlayingOfflineMessage() {
    if (!this.setupComplete) { return false; }

    const playlist = this.player.getPlaylist();

    return _.get(playlist, '[0].file') === this.constructor.offlineSource.file;
  }

  manageStream() {
    this.isSourceLive(this.sources[0], isLive => {
      console.log("live stream is online?", isLive);

      if (isLive && (!this.setupComplete || this.isPlayingOfflineMessage())) {
        this.playStream();
      } else if (!isLive && !this.isPlayingOfflineMessage()) {
        this.playOfflineMessage();
      }

      setTimeout(() => { this.manageStream() }, 5000);
    });
  }
}

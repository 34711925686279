const Utils = {};

// Creates a new object which inherits from the specified one.
// params:
//   source_obj: Object to inherit from
Utils.create_obj = function(source_obj) {
    function F() {};
    F.prototype = source_obj;
    return new F();
}

/*
 Inheritance helpers, as per: http://phrogz.net/js/Classes/OOPinJS2.html
*/
Utils.setup_inheritance = function( currentClassOrObject, parentClassOrObject ){
  if ( parentClassOrObject.constructor == Function )
  {
    //Normal Inheritance
    currentClassOrObject.prototype = new parentClassOrObject;
    currentClassOrObject.prototype.constructor = currentClassOrObject;
    currentClassOrObject.prototype.parent = parentClassOrObject.prototype;
  }
  else
  {
    //Pure Virtual Inheritance
    currentClassOrObject.prototype = parentClassOrObject;
    currentClassOrObject.prototype.constructor = currentClassOrObject;
    currentClassOrObject.prototype.parent = parentClassOrObject;
  }
  return currentClassOrObject;
}

Utils.call_callback = function(func) {
  if(typeof func == 'function') {
    // since arguments is not a real array, convert it to one, slicing off the first item
    var args = Array.prototype.slice.call(arguments,1);
    func.apply(null,args);
  }
}

// GET url
//
// params:
//   url: url of resource
//   cb_success: function to call with response
//     arg1: response
//   cb_error: called when an error occurs whilst submitting
//     arg1: text_status
//     arg2: error_thrown
//   cb_after: called after either success or an error
Utils.get = function(params) {
  $.ajax({
    type: 'get',
    url:  params.url,
    dataType: 'json'
  }).fail(function(request, text_status, error_thrown) {
    if(params.cb_error) {
      Utils.call_callback(params.cb_error, text_status, error_thrown);
    } else {
      alert('error: ' + text_status + ', ' + error_thrown);
    }
  }).done(function(resp) {
      Utils.call_callback(params.cb_success, resp);
  }).always(function(request, text_status) {
    Utils.call_callback(params.cb_after, request, text_status);
  });
}

// POST jquery form
//
// params:
//   $form: jquery form object
//   cb_success: function to call with response
//     arg1: response
//   cb_error: called when an error occurs whilst submitting
//     arg1: text_status
//     arg2: error_thrown
//   cb_after: called after either success or an error
Utils.submit = function(params) {
  $.ajax({
    type: params.$form.attr('method'),
    url:  params.$form.attr('action'),
    dataType: 'json',
    data: params.$form.serialize()
  }).fail(function(request, text_status, error_thrown) {
    if(params.cb_error) {
      Utils.call_callback(params.cb_error, text_status, error_thrown);
    } else {
      alert('error: ' + text_status + ', ' + error_thrown);
    }
  }).done(function(resp, text_status, xhr) {
    Utils.call_callback(params.cb_success, resp);
  }).always(function(request, text_status) {
    Utils.call_callback(params.cb_after, request, text_status);
  });
}

// POST given datahash to server with added authenticity token
// Call callback when done.
// This is used when we are creating a resource without a rails-generated form.
//
// params:
//   url: resource url
//   data: data to save in a hash
//   cb_success: function(data)
Utils.create = function(params) {
  var data = Utils.create_obj(params.data);
  data[Utils.fparam()] = Utils.ftoken();
  data['_method'] = 'post';

  $.ajax({
    url: params.url,
    type: 'post',
    data: data
  }).done(function(resp, text_status, request) {
    Utils.call_callback(params.cb_success, resp);
  }).fail(function(request, text_status, error_thrown) {
    alert("Could not create new item\n" + "Error: " + request.status.toString());
  });
}

Utils.postForm = function(params) {
  var data = Utils.create_obj(params.data);
  data[Utils.fparam()] = Utils.ftoken();
  data['_method'] = params.method || 'post';

  var html = '<form action="' + params.url + '" method="post">';
  for (var key in data) {
    html += '<input type="hidden" name="' + key + '" value="' + data[key] + '" />';
  }
  html += '</form>';

  obj = $(html).appendTo('body').submit();
}

// Save rails generated form data specified in datahash
//
// params:
//   rurl: resource url
//   data: data to save in a hash
//   cb_success: function(data)
Utils.save = function(params) {
  var data = Utils.create_obj(params.data);
  data[Utils.fparam()] = Utils.ftoken();
  data['_method'] = 'post';

  $.ajax({
    url: params.url,
    data: data,
    dataType: 'json',
    type: 'post'
  }).done(function(resp, text_status, request) {
    Utils.call_callback(params.cb_success, resp);
  }).fail(function(request, text_status, error_thrown) {
    alert("Save failed\n" + "Error: " + request.status.toString());
  });
}

// Save the order of a list of items
//
// params:
//   rurl: resource url
//   order: array of hashes - [{id: x, position: y}]
Utils.update_order = function(rurl,order,callback) {
    var data = {};
    data[Utils.fparam()] = Utils.ftoken();
    data['order'] = order;

    $.ajax({
      url: rurl + '/update_order',
      data: data,
      dataType: 'json',
      type: 'post'
    }).fail(function(request, text_status, error_thrown) {
      alert("Updating order failed\n" + "Error: " + request.status.toString());
    }).done(function(resp) {
      if(resp.errors) {
          alert("Updating order failed: " + resp.errors.join(', '));
      }
      Utils.call_callback(callback,resp);
    });
}

// Delete resource at specified url
//
// params:
//   url: resource url
//   cb_success: function called after success
//   cb_error: called when an error occurs whilst submitting
//     arg1: text_status
//     arg2: error_thrown
//   cb_after: function called after either success or an error
Utils.del = function(params) {
  var data = {};
  data[Utils.fparam()] = Utils.ftoken();
  data['_method'] = 'delete';

  $.ajax({
    url: params.url,
    data: data,
    dataType: 'json',
    type: 'post'
  }).done(function(resp, text_status, request) {
    Utils.call_callback(params.cb_success, resp);
  }).fail(function(request, text_status, error_thrown) {
    if(params.cb_error) {
      Utils.call_callback(params.cb_error, text_status, error_thrown);
    } else {
      alert('error: ' + text_status + ', ' + error_thrown);
    }
  }).always(function(request, text_status) {
    Utils.call_callback(params.cb_after, request, text_status);
  });
}

// Deletes list item, with animation
Utils.json_delete_list_item = function($li,rurl,callback) {
  if(confirm('Are you sure you want to delete this item?')) {
    var data = {};
    data['_method'] = 'delete';
    data[Utils.fparam()] = Utils.ftoken();

    $.ajax({
      url: rurl,
      dataType: 'json',
      data: data,
      type: 'post'
    }).fail(function(request, text_status, error_thrown) {
      $li.effect(
        'highlight',{color: '#f88'}, 1600
      );
    }).done(function(resp) {
      $li.animate(
        { height: 0, width: 0, paddingLeft: 0, paddingRight: 0 },
        400,
        function() {
          $li.remove();
        }
      );
      Utils.call_callback(callback,resp);
    });

    return true;
  } else {
    return false;
  }
}

// Authenticity token
Utils.fparam = function() {
    return $('head meta[name="csrf-param"]').attr('content');
}
Utils.ftoken = function() {
    return $('head meta[name="csrf-token"]').attr('content');
}

Utils.csrfParams = function () {
  var data = {};
  data[Utils.fparam()] = Utils.ftoken();
  return data;
}

// Form field hints
Utils.enable_form_field_hints = function() {
  $('div.entry.hint').each(function() {
    var $entry = $(this);
    var $label = $entry.find('label:first');
    var $input = $entry.find('input');

    // We don't want label hint to appear if someone has js disabled,
    // so turn on manually. Also, only show if field isn't pre-filled.
    if( $input.val().length == 0 ) {
      $label.addClass('visible');
    }

    // Click through label.
    // Note that this isn't perfect, but good enough.
    $label.click(function() {
      $input.get(0).focus();
    });

    $input.focusin(function() {
      $label.removeClass('visible');
    });
    $input.focusout(function() {
      // show if field is empty
      if( $input.val().length == 0 ) {
        $label.addClass('visible');
      }
    });
  });
}

export { Utils as default };

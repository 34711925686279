import _                       from 'lodash';
import moment                  from 'moment';
import settings                from './settings';
import CommentsEditor          from './components/comments_editor';
import ContentEditor           from './components/content_editor';
import Cookies                 from './lib/cookie';
import DiscussionsEditor       from './components/discussions_editor';
import EnhancedListEditor      from './components/enhanced_list_editor';
import EventAssociationsEditor from './components/event_associations_editor';
import EventEditor             from './components/event_editor';
import GroupAssociationEditor  from './components/group_association_editor';
import GroupMessageEditor      from './components/group_message_editor';
import LiveStreamAlerts        from './components/live_stream_alerts';
import { LiveStreamPlayer }    from './components/live';
import PageEditor              from './components/page_editor';
import Slideshow               from './components/slideshow';
import Uploader                from './components/uploader';
import Utils                   from './utils';
import WidgetEditor            from './components/widget_editor';

const loadedJavascripts = {};

moment.updateLocale(settings.langLocaleMap[document.documentElement.lang]);
$.fn.datepicker.defaults.language = settings.langLocaleMap[document.documentElement.lang];

// Load a JS script, blocking until done.
// Script will only be loaded once.
function loadJavascript(scriptURL) {
  if (loadedJavascripts[scriptURL] === true) {
    return $.Deferred().resolve();
  }

  if (!loadedJavascripts[scriptURL]) {
    loadedJavascripts[scriptURL] = $.ajax(
      { url: scriptURL, dataType: 'script' }
    ).then(function () { loadedJavascripts[scriptURL] = $.Deferred().resolve(); });
  }

  return loadedJavascripts[scriptURL];
}

$(document).ready(function(){
  // add date/time helpers
  //$('.datefield').datepicker(settings.datePicker);

  $('.autoselect').click(function() {
    e = $(this).get(0);
    e.focus();
    e.select();
  });

  // add link tracking
  $('#container a').each(function() {
    var $this = $(this);
    var href = $this.attr('href');

    if(href == undefined) {
      return;
    } else if(href.search(/.+\.(mp3|jpe?g|pdf|docx?)$/i)!=-1) {                // monitor all downloads
      $this.click(function() {pageTracker._trackEvent('link','download',href);});
    } else if( href.search(/(ftp|https?):\/\/theswitch\.com\/.*/i)==0 ) {    // exclude local non relative links
      return;
    } else if( href.search(/^((ftp|https?):\/\/).+/i)!=-1 ) {                // monitor external links
      $this.click(function() {pageTracker._trackEvent('link','external',href);});
    }
  });

  $('a.set-locale').click(function (e) {
    var $el = $(e.currentTarget);

    Cookies.set('locale', $el.data('locale'));
  });

  $('#content select:first, input[type!=hidden]:first').first().each(function () {
    const $el = $(this);

    if (!$el.hasClass('datefield')) {
      $el.focus();
      const val = $el.val();
      $el.val('').val(val);
    }
  });

  // list sorting
  $('.reorder ul').sortable();
  $('.reorder ul').disableSelection();

  // enchance static slideshow
  if($('#content .static_slideshow').length && $(window).height() < 800) {
    $.scrollTo('#content');
  }

  // set up links for js slideshow
  $('.slideshowable').each(function () {
    new Slideshow({ $element: $(this) });
  });

  // generate email adresses with js to avoid harvesting bots
  jQuery('span.js_email').each(function () {
    const $i = jQuery(this);
    const ea = $i.attr('title') + '@' + 'dandypo' + 'lish.org.au';
    $i.replaceWith('<a href="mail' + 'to:' + ea + '">' + ea + '</a>');
  });

  Utils.enable_form_field_hints();

  $('.live-stream-player').each(function () {
    new LiveStreamPlayer($(this));
  });

  $('.livestream-alerts').each(function () {
    new LiveStreamAlerts($(this));
  });

  $('.custom-file').each(function () {
    const $label = $(this).find('.custom-file-label');

    $('.custom-file input[type=file]').on('change', function () {
      const fileName = _.last($(this).val().split(/[\\\/]/g));
      $label.text(fileName);
    });
  });

  //
  // --- ADMIN ---
  //

  $('div.photos.caption .next').click(function(event) {
    nextCaption(event.currentTarget);
    return false;
  });

  $('div.edit.page').each(function() {
    new PageEditor($(this));
  });

  $('div.edit.content').each(function() {
    new ContentEditor($(this));
  });

  $('div.enhanced_list').each(function() {
    new EnhancedListEditor({$element: $(this)});
  });

  $('div.photos .uploader').each(function(i, el) {
    new Uploader({
      el             : $(el),
      onItemUploaded : function (resp) {
        $($.parseHTML(resp.html)).hide().appendTo('.photo-album-view').fadeIn('slow');
      }
    });
  });

  $('.lessons .uploader').each(function (i, el) {
    new Uploader({ el: $(el) });
  });

  // Check+disable other role checkboxes if organization_admin is ticked
  $('form.person .form-group.roles ').each(function() {
    var $el = $(this);
    var $adminCheck = $el.find('input[data-role=organization_admin]:enabled');

    if ($adminCheck.length == 0) return;

    function manageCheckBoxes (adminChecked) {
      $el.find('input[type=checkbox][data-role!=organization_admin]').prop('disabled', adminChecked);
    }

    $adminCheck.change(function () {
      manageCheckBoxes(this.checked);
    });

    $adminCheck.trigger('change');
  });

  // enable deleting list items
  $(document).on('click', '.enhanced li a.delete', function(event) {
    var $link = $(this);
    var $li = $link.parents('li:first');

    Utils.json_delete_list_item($li,$link.attr('href'));

    return false;
  });

  // Enable deleting items
  $(document).on('click', 'a[data-method=delete]', function(e) {
    e.preventDefault();

    var target = $(e.target).closest('a');
    if(confirm(target.data('confirm'))) {
      Utils.del({
        url: target.attr('href'),
        cb_success: function(data) {
          if(data.redirect) window.location.href = data.redirect;
        }
      });
    }
  });

  // Enable custom method links (non delete)
  $(document).on('click', 'a[data-method][data-method!=delete]', function (e) {
    e.preventDefault();

    var a = $(e.target).closest('a');
    var method = a.data('method');
    var confirmText = a.data('confirm');

    run = function () {
      Utils.postForm({
        url: a.attr('href'),
        method: method
      })
    }

    if (method in ['put', 'post'] || confirmText) {
      if (confirm(confirmText || "Continue?")) {
        run();
      }
    } else {
      run();
    }
  });

    // add link tracking
  $('#container a').each(function() {
    var $this = $(this);
    var href = $this.attr('href');

    if(typeof href == 'string') {
      href = href.split('?')[0];

      if(href.search(/.+\.(mp3|zip|jpe?g|pdf|docx?)/i)!=-1) {               // monitor all downloads
        $this.click(function() {
          gtag('event', 'link', { event_category: 'download', value: href });
        });
      } else if( href.search(/(ftp|https?):\/\/ww[w2]\.dandypolish\.org\.au\/.*/i)==0 ) {   // exclude local non relative links
        return;
      } else if( href.search(/^((mms|ftp|https?):\/\/).+/i)!=-1 ) {               // monitor external links
        $this.click(function() {
          gtag('event', 'link', { event_category: 'external', value: href });
        });
      }
    }
  })

  $('.meal-orders').each(function () {
    var $mealOrders      = $(this);
    var $meals           = $mealOrders.find('.meal');
    var $mealsGrandTotal = $mealOrders.find('.meals-grand-total');

    function mealPricing($meal) {
      return _.sum($meal.find('input:enabled').map(function (i, el) {
        var $inp = $(el);

        return $inp.val() * $inp.data('price');
      }));
    }

    function formatPrice(price) {
      return "$" + price.toFixed(2)
    }

    function setInputPrice($input, price) {
      $input.val(formatPrice(price));
    }

    function updateMealPricing($meal) {
      $meal.find('input.total-price').val(formatPrice(mealPricing($meal)));
    }

    function updateGrandTotal() {
      var total = _.sum($meals.map(function (i, el) {
        return mealPricing($(el));
      }));
      var $total = $mealsGrandTotal.find('.total-price');

      $total.html(formatPrice(total));
    }

    function initialise() {
      $mealOrders.find('.meal input:enabled').on('change', function (e) {
        var $meal = $(e.target).closest('.meal');

        updateMealPricing($meal);
        updateGrandTotal();
      });

      $mealOrders.find('form').on('submit', function () {
        $mealOrders.find('.meals-submit button[type=submit]').prop('disabled', true)
                                                             .find('.spinner').removeClass('hidden');
      });

      $meals.map(function (i, el) { updateMealPricing($(el)) });
      updateGrandTotal();
    }

    initialise();
  });

  $('.edit.group.association').each(function() {
    new GroupAssociationEditor({$element: $(this)});
  });

  $('.new.group_message, .edit.group_message').each(function() {
    new GroupMessageEditor({$element: $(this)});
  });

  $('.commentable').each(function() {
    new CommentsEditor({$element: $(this)});
  });

  $('.discussions').each(function() {
    new DiscussionsEditor({$element: $(this)});
  });

  $('.new.widget, .edit.widget').each(function() {
    new WidgetEditor($(this));
  });

  $('.new.event, .edit.event').each(function () {
    new EventEditor($(this));
  });

  $('.event-associations').each(function () {
    new EventAssociationsEditor($(this));
  });

  document.querySelectorAll('table.hymns').forEach((table) => {
    const tbody = table.querySelector('tbody');
    const rows  = tbody.querySelectorAll('tr');

    const sortHymns = (sortLang, sortType) => {
      const sorted = _.sortBy(rows, (row) => {
        if (sortType == 'num') {
          const numString = row.attributes[`data-num-${sortLang}`].value;
          const num = parseInt(numString, 10);

          if (_.isFinite(num)) {
            return num;
          } else {
            return Number.MAX_SAFE_INTEGER;
          }
        } else if (sortType == 'title') {
          const titleString = row.attributes[`data-title-${sortLang}`].value.toLocaleLowerCase().replace(/’|'|\(|\)/g, '');

          if (titleString.length > 0) {
            return titleString;
          } else {
            return 'zzz';
          }
        }
      });
      sorted.forEach((row) => {
        tbody.appendChild(row);
      });
    };

    table.querySelectorAll('thead th a').forEach((sortLink) => {
      sortLink.addEventListener('click', (event) => {
        const sortLang = sortLink.attributes['data-sort-by-lang'].value;
        const sortType = sortLink.attributes['data-sort-type'].value;

        event.preventDefault();

        sortHymns(sortLang, sortType);
      });
    });
  });

  enable_mediaplayer_functionality();
});

function nextCaption(target) {
  var $nextItem = $(target).parents('li:first').next();

  $.scrollTo(
    $nextItem,
    { axis: 'y', offset: -50, duration: 400, easing: 'easeInOutCubic' }
  );

  $nextItem.find('input[type=text]').focus()
}

function enable_mediaplayer_functionality() {
  $('.audio-player').each(function () {
    var $el = $(this);
    var $audio = $el.find('audio');

    loadJavascript('/javascripts/mediaelement-4.2.9/mediaelement-and-player.min.js')
    .then(function () {
      return loadJavascript('/javascripts/mediaelement-4.2.9/google-analytics.min.js');
    })
    .then(function () {
      $audio.mediaelementplayer({
        pluginPath: '/mediaelement-4.2.9/'
      });
    });

  });
}

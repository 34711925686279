import ResourceDialog from './resource_dialog';

// params:
//  url: url to dialog form
//  cbAdd: called when a new moderation is added
//    params:
//      response data object
//  cbAfter: called after either success or failure
export default class ModerationsEditor {

  constructor(params = {}) {
    this.params = params;
    if (!this.params.cbAdd) {
      this.params.cbAdd = function() {};
    }
    if (!this.params.cbAfter) {
      this.params.cbAfter = function() {};
    }
    this.setup();
  }

  setup() {
    new ResourceDialog({
      title: "Moderate",
      okCaption: "Change",
      url: this.params.url,
      cbSuccess: resp => {
        this.params.cbAdd(resp);
      },
      cbAfter: () => {
        this.params.cbAfter();
      }
    });
  }
}

import _             from 'lodash';
import BaseComponent from './_base_component';
import Utils         from '../utils';


export default class EventAssociationsEditor extends BaseComponent {
  constructor(el) {
    super(...arguments);
    this.el = el;
    this.render();

    this.eventId = this.el.data('event-id');
    this.url     = this.el.data('url');

    this.page = 1;
    this.fetchAndUpdate();
  }

  refreshElements() {
    if (this.searchInput) {
      this.searchInput.off();
    }
    if (this.paginationBtns) {
      this.paginationBtns.off();
    }
    if (this.linkBtns) {
      this.linkBtns.off();
    }

    this.list           = this.el.find('.list');
    this.searchInput    = this.el.find('.search input');
    this.paginationBtns = this.el.find('.pagination li a');
    this.linkBtns       = this.el.find('.list button.associate');

    this.searchInput.on('change keyup mouseup', e => this.onSearch(e));
    this.paginationBtns.on('click',             e => this.onPaginate(e));
    this.linkBtns.on('click',                   e => this.onToggleAssociation(e));
  }

  render() {
    this.el.html(this.view('event_associations/index'));
    this.refreshElements();
  }

  renderList(resp) {
    this.list.html(this.view('event_associations/_list', { data: resp, eventId: this.eventId }));
    this.refreshElements();
  }

  fetchAndUpdate() {
    return $.ajax({
      url: this.url,
      data: { q: this.q, p: this.page }
    }).done(resp => {
      this.renderList(resp);
    });
  }

  onSearch() {
    window.clearTimeout(this.timeout);
    this.q = this.searchInput.val();

    this.timeout = window.setTimeout(
      () => {
        if (this.q === this.last_q) {
          return;
        }

        // Reset page to 1 if search term changes
        this.page = 1;

        this.fetchAndUpdate().done(() => {
          this.last_q = this.q;
        });
      },
      200
    );
  }

  onPaginate(e) {
    e.preventDefault();
    const btn = $(e.currentTarget);

    if (!btn.closest('li').hasClass('avail')) {
      return;
    }

    this.page = parseInt(btn.data('page'));
    this.fetchAndUpdate();
  }

  onToggleAssociation(e) {
    e.preventDefault();
    const btn = $(e.currentTarget);

    $.ajax({
      url: this.url,
      method: 'post',
      data: _.extend(Utils.csrfParams(),
        { id: btn.data('id'), model_type: btn.data('model-type') }
      )
    }).done(() => {
      return this.fetchAndUpdate();
    });
  }
}

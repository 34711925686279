export default class WidgetEditor {
  constructor(el) {
    this.el                  = el;
    this.form                = this.el.find('form');
    this.radios              = this.form.find('.form-group.type input[type=radio]');
    this.organizationSel     = this.form.find('select[name="widget[organization_id]"]');
    this.referenceSel        = this.form.find('select[name="widget[reference]"]');
    this.typedFormGroups     = this.form.find(".form-group[data-type]");
    this.typeChanged();
    this.organizationChanged();

    this.radios.change(() => this.typeChanged());
  }

  organizationChanged() {
    const id = this.organizationSel.val();

    if (id) {
      this.referenceSel.find(`optgroup[data-id=${id}]`).removeClass('hidden');
      this.referenceSel.find(`optgroup[data-id!=${id}]`).addClass('hidden');
    } else {
      this.referenceSel.find("optgroup").addClass('hidden');
    }

    if (this.referenceSel.find('option:selected').parent().hasClass('hidden')) {
      this.referenceSel.val('');
    }
  }

  typeChanged() {
    const inputSelector = 'input,select,textarea';
    const selected = this.radios.filter(':checked');

    this.typedFormGroups.removeClass('visible');
    this.typedFormGroups.find(inputSelector).prop('disabled', true);


    if (selected.length === 1) {
      const groups = this.typedFormGroups.filter(`[data-type='${selected.data('type')}']`);

      groups.addClass('visible');
      groups.find(inputSelector).prop('disabled', false);

      this.organizationSel.change(() => this.organizationChanged());
    }
  }
}

const langLocaleMap = { en: 'en-AU', pl: 'pl' };

const settings = {
  langLocaleMap: langLocaleMap,
  datePicker: {
    format: 'yyyy-mm-dd',
    autoclose: true,
    weekStart: 0
  },
  tinymce: {
    // Location of TinyMCE script
    // Following line only needed when using tinymce from /public rather than node_modules
    //script_url : '/javascripts/tinymce-5.2.0/tinymce.min.js',

    // General options
    skin    : false, // Prevent tinymce loading a skin itself & generating errors. We load the skin manually.
    plugins : "table hr image link code lists media searchreplace charmap print paste directionality fullscreen noneditable visualchars nonbreaking",
    height  : 650,
    resize  : true,
    relative_urls: false,

    block_formats: 'Header=h3,Preformatted=pre',
    invalid_elements : 'link,meta,xml',
    extended_valid_elements: 'script[type|src]',
    image_advtab: true,

    style_formats : [
      { title: 'Heading',     block: 'h3' },
      { title: 'Paragraph',   block: 'p' },
      { title: 'Important',   inline: 'span', classes: 'important' },
      { title: 'Sub-heading', inline: 'span', classes: 'subheading' },
      { title: 'Heading font',inline: 'span', classes: 'heading-text' },
      { title: 'Shaded out',  inline: 'span', classes: 'shadedout' },
      { title: 'Small',       inline: 'small' },
      { title: '← Image left',  selector: 'img', styles: { 'float': 'left'  } },
      { title: '→ Image right', selector: 'img', styles: { 'float': 'right' } }
    ],

    // Theme options
    menubar         : false,
    toolbar1        : "newdocument | undo redo | styleselect | bold italic underline strikethrough | subscript superscript | alignleft aligncenter alignright alignjustify | table",
    toolbar2        : "cut copy paste pastetext | searchreplace | outdent indent blockquote numlist bullist | link unlink image code",
    toolbar3        : "hr removeformat | sub sup | charmap media advhr | print fullscreen | visualchars nonbreaking | adddocument addimages addvideos addaudiorecordings",
    statusbar       : true,
    object_resizing : false,

    // Content CSS
    content_css : document.querySelector('head meta[name=anon-css]').getAttribute('content')
  }
};

export { settings as default };

var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (body, closeButtonCaption, okButtonCaption, title) {
      pug_html = pug_html + "\u003Cdiv class=\"modal fade\" tabindex=\"-1\" role=\"dialog\"\u003E\u003Cdiv class=\"modal-dialog\" role=\"document\"\u003E\u003Cdiv class=\"modal-content\"\u003E\u003Cdiv class=\"modal-header\"\u003E\u003Cbutton class=\"close pull-right\" type=\"button\" data-dismiss=\"modal\" aria-label=\"Close\"\u003E\u003Cspan aria-hidden=\"true\"\u003E&times;\u003C\u002Fspan\u003E\u003C\u002Fbutton\u003E\u003Ch4 class=\"modal-title\"\u003E" + (pug.escape(null == (pug_interp = title) ? "" : pug_interp)) + "\u003C\u002Fh4\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"modal-body\"\u003E" + (null == (pug_interp = body) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"modal-footer\"\u003E\u003Cdiv class=\"spinner hidden\"\u003E\u003C\u002Fdiv\u003E\u003Cbutton class=\"btn btn-primary ok\" type=\"button\"\u003E" + (pug.escape(null == (pug_interp = okButtonCaption) ? "" : pug_interp)) + "\u003C\u002Fbutton\u003E\u003Cbutton class=\"btn btn-default cancel\" type=\"button\" data-dismiss=\"modal\"\u003E" + (pug.escape(null == (pug_interp = closeButtonCaption) ? "" : pug_interp)) + "\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "body" in locals_for_with ?
        locals_for_with.body :
        typeof body !== 'undefined' ? body : undefined, "closeButtonCaption" in locals_for_with ?
        locals_for_with.closeButtonCaption :
        typeof closeButtonCaption !== 'undefined' ? closeButtonCaption : undefined, "okButtonCaption" in locals_for_with ?
        locals_for_with.okButtonCaption :
        typeof okButtonCaption !== 'undefined' ? okButtonCaption : undefined, "title" in locals_for_with ?
        locals_for_with.title :
        typeof title !== 'undefined' ? title : undefined));
    ;;return pug_html;};
module.exports = template;
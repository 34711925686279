import Utils from '../utils';


export default function EnhancedListEditor(params) {
  var $element = params.$element;
  var $list    = $element.find('.list ol');
  var $spinner = $element.find('.spinner');
  var postUrl  = $element.data('url');

  function initialize() {
    enableDeleting();
    enableSorting();
    enablePostingParam();
  }

  function enableDeleting() {
    $list.find('li .delete').click(function() {
      var $this = $(this);
      var $li = $this.parents('li:first');

      spinner(true);

      var ret = Utils.json_delete_list_item($li,$this.attr('href'),function() {
        $list.sortable('refresh');
        spinner(false);
      });

      if (!ret) spinner(false);

      return false;
    })
  }

  function enableSorting() {
    $list.sortable({
      update: function(event,ui) {
        /* Save new order when order changes */
        var order = {};

        spinner(true);

        $list.find('li').each(function(index) {
          var id = $(this).data('id');
          if(id) order[id] = index;
        });
        Utils.update_order(postUrl, order, function() {
          spinner(false);
        });
      }
    });
  }

  function enablePostingParam() {
    $list.find('li button[type=submit]').click(function (e) {
      var $this = $(this);
      var $li = $this.parents('li:first');

      e.preventDefault();

      spinner(true);

      var data = Utils.csrfParams();
      data[$this.attr('name')] = $this.attr('value');

      $.ajax({
        url: postUrl + '/' + $li.data('id'),
        data: data,
        method: 'patch'
      }).done(function (resp) {
        if (resp.reload) {
          window.location.reload();
        }
      });
    });
  }

  function spinner(state) {
    $spinner.toggleClass('hidden', !state);
  }

  initialize();
}

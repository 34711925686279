import Utils from '../utils';


export default function GroupAssociationEditor(params) {

  var $element;
  var $people;
  var $members;
  var $nonmembers;

  function initialize() {
    $element = params.$element;
    $people = $element.find('.people .list li');
    $members = $element.find('.included.people');
    $nonmembers = $element.find('.excluded.people');

    // enable adding/removing
    $people.find('.control.remove').click(function() {
      remove_person($(this).parents('li:first'));
    });
    $people.find('.control.add').click(function() {
      add_person($(this).parents('li:first'));
    });

    // enable search
    $element.find('.people').each(function() {
      var $people = $(this);
      var $search = $people.find('.search');

      $search.find('form').submit(function() {return false});

      $search.find('input[type=text]:first').on('keyup',function() {
        var $items = $people.find('.list li');
        var search_term = $(this).val().toLowerCase().replace(/[\s]+/g,'');
        $people.data('search-term',search_term);

        if(search_term.length > 0) {
          $items.each(function() {
            filter_person_by_search($people, $(this));
          });
        } else {
          $items.show();
        }
      });
    });
  }

  function add_person($person) {
    Utils.create({
      url: $element.data('association-url'),
      data: {person_id: $person.data('id')},
      cb_success: function(resp) {
        if(resp.errors) {
          alert("Error: " + resp.errors.join(','));
        } else {
          $person.detach();

          insert_into_sorted_list($members,$person)
        }
      }
    });
  }

  function remove_person($person) {
    var id = $person.data('id');
    Utils.del({
      url: $element.data('association-url') + '/' + id,
      data: {person_id: id},
      cb_success: function(resp) {
        $person.detach();

        insert_into_sorted_list($nonmembers,$person)
      }
    });
  }

  function insert_into_sorted_list($list, $person) {
    var $items = $list.find('.list ol li');

    filter_person_by_search($list,$person);

    for(var a = 0; a < $items.length; a++) {
      var $comp_item = $($items[a]);

      if($person.data('sort') < $comp_item.data('sort')) {
        $person.insertBefore($comp_item);
        break;
      }
    }
    if(a == $items.length) {
      $list.find('ol').append($person);
    }
  }

  function filter_person_by_search($list, $person) {
    var search_term = $list.data('search-term');

    if(typeof search_term == 'undefined' || $person.data('sort').indexOf(search_term) > -1) {
      $person.show();
    } else {
      $person.hide();
    }
  }

  function sort($list) {
    $list.children().sort(
      function(a,b) {
        return $(a).data('sort') > $(b).data('sort') ? 1 : -1;
      }
    ).appendTo($list);
  }

  initialize();
}

import BaseComponent from './_base_component';


export default class LiveStreamAlerts extends BaseComponent {
  constructor(el) {
    super(...arguments);
    this.el = el;
    this.placeholder     = this.el.find('.placeholder');
    this.videosContainer = this.el.find('.live-videos');

    this.fetchLiveVideos();
  }

  renderLiveVideos(videos) {
    if (videos.length > 0) {
      this.placeholder.addClass('hidden');
    }

    for (let video of videos) {
      if (this.videosContainer.find(`.live-video[data-id='${video.id}']`).length > 0) {
        continue;
      } else {
        this.videosContainer.append(this.view('live_stream_alerts/live_video', {video}));
      }
    }

  }

  fetchLiveVideos() {
    return $.get('/media/videos/live')
    .then(data => {
      this.renderLiveVideos(data.items);

    }).always(() => {
      setTimeout(
        () => this.fetchLiveVideos(),
        9000
      );
    });
  }
}

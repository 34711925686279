var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (video) {
      pug_html = pug_html + "\u003Cdiv" + (" class=\"live-video\""+pug.attr("data-id", video.id, true, true)) + "\u003E\u003Cdiv class=\"video-wrap\"\u003E\u003Ciframe" + (" width=\"650\" height=\"366\""+pug.attr("src", ("https://www.youtube.com/embed/"+video.id), true, true)+" frameborder=\"0\""+pug.attr("allowfullscreen", true, true, true)) + "\u003E\u003C\u002Fiframe\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "video" in locals_for_with ?
        locals_for_with.video :
        typeof video !== 'undefined' ? video : undefined));
    ;;return pug_html;};
module.exports = template;
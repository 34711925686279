import AudioDialog          from './audio_dialog';
import { LiveStreamDialog } from './live';
import TinymceEditor        from './tinymce_editor';
import UploadedFileDialog   from './uploaded_file_dialog';
import VideoDialog          from './video_dialog';


export default class PageEditor {
  constructor(el) {
    this.el = el;
    this.data = JSON.parse(this.el.find(' > .invisible .data').text());
    this.setupEditorFeatures();
  }

  setupEditorFeatures() {
    this.setupImageDialog();
    this.setupVideoDialog();
    this.setupAudioDialog();
    this.setupLiveStreamDialog();
    this.setupEditor();
  }

  setupDocumentDialog() {
    this.documentDialog = new UploadedFileDialog({html: this.data.addDocumentsModal});
  }

  setupImageDialog() {
    this.imageDialog = new UploadedFileDialog({html: this.data.addImagesModal});
  }

  setupVideoDialog() {
    this.videoDialog = new VideoDialog({html: this.data.addVideosModal});
  }

  setupAudioDialog() {
    this.audioDialog = new AudioDialog({html: this.data.addAudiosModal});
  }

  setupLiveStreamDialog() {
    this.liveStreamDialog = new LiveStreamDialog();
  }

  setupEditor() {
    this.editor = new TinymceEditor({
      target: this.el.find('textarea.text'),
      dialogs: {
        document: this.documentDialog,
        image:    this.imageDialog,
        video:    this.videoDialog,
        audio:    this.audioDialog,
        live:     this.liveStreamDialog
      }
    });
  }
}

import _        from 'lodash';
import Uploader from './uploader';
import Utils    from '../utils';


export default class UploadedFileDialog {
  constructor(opts) {
    this.dialog = $(opts.html).appendTo('body');

    this.tabs           = this.dialog.find('.tabs');
    this.navTabs        = this.tabs.find('.nav-tabs');
    this.options        = this.dialog.find('.tab-pane.select .options');
    this.itemsContainer = this.dialog.find('.tab-pane.select .items');
    this.insertSpinner  = this.dialog.find('.modal-footer .spinner');

    this.setupUploader();
    this.enhanceItems();

    this.dialog.find('button.insert').click(() => {
      if (this.getAndInsertImages()) {
        this.insertSpinner.removeClass('hidden');
      }
    });
  }

  enhanceItems() {
    for (let item of this.allItems()) {
      this.enhanceItem($(item));
    }
  }

  enhanceItem(item) {
    item.children('.container').click(() => {
      if (!this.multiSelect) { this.deselectAll(); }
      item.toggleClass('selected');
    });
    item.find('a.delete').click((e) => {
      e.preventDefault();
      e.stopPropagation();

      const a = $(e.currentTarget);

      Utils.json_delete_list_item(item, a.prop('href'));
    });
  }

  addItems(items) {
    items = $(items);
    items.appendTo(this.itemsContainer).fadeIn('slow');
    items.each((i, item) => {
      this.enhanceItem($(item));
    });
  }

  allItems() {
    return this.itemsContainer.find('.item');
  }

  deselectAll() {
    this.allItems().removeClass('selected');
  }

  setupUploader() {
    return new Uploader({
      el             : this.dialog.find('.tab-pane.upload .uploader'),
      onItemUploaded : (resp) => {
        this.dialog.find('.noitems').hide('slow', () => $(this).remove() );
        this.addItems($.parseHTML($.trim(resp.code)));
      },
      onAllUploaded  : () => {
        this.navTabs.find('a:first').tab('show');
        this.itemsContainer.scrollTop(this.itemsContainer.prop('scrollHeight'));
      }
    });
  }

  insertImageTags(resp) {
    if (resp.data) {
      this.dialog.modal('hide');
      this.callback(resp.data);
    } else {
      this.dialog.find('.errors').text(resp.error);
    }
  }

  getAndInsertImages() {
    // insert images into tinymce
    let ids = [];
    const size = this.dialog.find('.options .sizes input[type=radio]:checked').val();

    ids = _.map(this.allItems().filter('.selected'), item => $(item).data('id'));

    if (ids.length > 0) {
      const ret = {};
      const reqdata = {};
      reqdata['ids'] = ids;
      reqdata['size'] = size;
      reqdata['abstract_image'] = this.abstractImage;
      reqdata[Utils.fparam()] = Utils.ftoken();

      $.ajax({
        type: 'POST',
        url: this.dialog.data('tags-url'),
        dataType: 'json',
        data: reqdata}).fail((request, textStatus, errorThrown) => {
        ret['error'] = `${textStatus}, ${errorThrown}`;
        this.insertImageTags(ret);

      }).done(dat => {
        ret['data'] = dat;
        this.insertImageTags(ret);
        this.deselectAll();
        this.insertSpinner.addClass('hidden');
      });

      return true;
    } else {
      return false;
    }
  }

  open(abstractImage, callback) {
    this.abstractImage = abstractImage;
    this.callback = callback;
    if (this.abstractImage) {
      this.options.hide();
      this.deselectAll();
      this.multiSelect = false;
    } else {
      this.options.show();
      this.multiSelect = true;
    }

    const tab = this.allItems().length > 0 ? 'first' : 'last';
    this.navTabs.find(`a:${tab}`).tab('show');
    this.dialog.modal('show');
  }
}

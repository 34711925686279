import ResourceDialog from '../resource_dialog';


export default class CommentsStateEditor {
  constructor(params) {
    this.parent = params.parent;

    this.setupStateEditor();
  }

  setupStateEditor() {
    this.meta         = this.parent.comments.children('.meta');
    this.modalContent = this.meta.find('.state.dialog');

    this.meta.find('a[data-role=change-state]').click(e => {
      e.preventDefault();
      const a = $(e.currentTarget);

      if (a.data('in_progress')) { return; }
      a.data('in_progress', true);

      new ResourceDialog({
        title: "Comments settings",
        okCaption: "Save",
        modalContent: this.modalContent,
        cbSuccess: resp => {
          this.meta.replaceWith($($.parseHTML($.trim(resp.meta_code))));
          this.setupStateEditor();
        },
        cbAfter: () => {
          a.data('in_progress', false);
        }
      });
    });
  }
}

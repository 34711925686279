import _                    from 'lodash';
import BaseComponent        from './_base_component';
import PhotoSwipe           from 'photoswipe';
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default';


export default class Slideshow extends BaseComponent {
  constructor(params) {
    super(...arguments);

    this.el   = params.$element;
    this.pswp = this.el.find('.pswp');

    const $data = this.el.find('.data');


    if (this.pswp.length == 0) {
      var html = this.view('slideshow/markup', {});

      this.el.append(html);
      this.pswp = this.el.find('.pswp');
    }

    this.items = JSON.parse($data.text());

    this.el.find('.photo a').each((i, item) => {
      $(item).on('click tap', (e) => {
        e.stopPropagation();
        e.preventDefault();

        var id    = $(item).closest('.photo').data('id');
        var index = _.findIndex(this.items, { id: parseInt(id) });
        var $img  = $(item).find('img');

        if (index !== -1) {
          this.start(index, $img[0]);
        } else {
          console.error("slideshow photo index not found", index);
        }
      });
    });
  }

  start(index, img) {
    var options = {
      index: index || 0,
      getThumbBoundsFn: (index) => {
        var pageYScroll = window.pageYOffset || document.documentElement.scrollTop;
        var rect = img.getBoundingClientRect();

        return {
          x: rect.left,
          y: rect.top + pageYScroll,
          w: rect.width
        };
      }
    };

    this.gallery = new PhotoSwipe(this.pswp[0], PhotoSwipeUI_Default, this.items, options);
    this.gallery.init();
  }
}

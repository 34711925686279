import BaseComponent from './_base_component';
import Utils         from '../utils';
import settings      from '../settings';

// params:
//  url: url to dialog code
//     OR
//  dialog: jquery dialog element
//  cbSuccess: success callback
//  cbAfter: called after either successful close or error
export default class ResourceDialog extends BaseComponent {

  constructor(params) {
    super(...arguments);
    this.params = params;
    if (!this.params.cbAfter) {
      this.params.cbAfter = function() {};
    }
    if (!this.params.cbSuccess) {
      this.params.cbSuccess = function() {};
    }
    this.initializeDialog();
  }

  initializeDialog() {
    if (this.params.url) {
      Utils.get({
        url: this.params.url,
        cb_success: resp => {
          this.createModal($($.parseHTML($.trim(resp.code))));
        },
        cb_error: (text_status, error_thrown) => {
          alert(`error: ${text_status}, ${error_thrown}`);
          this.params.cbAfter();
        }
      });
    } else if (this.params.modalContent) {
      this.createModal(this.params.modalContent);
    }
  }

  createModal(modalContent) {
    this.form = modalContent.find('form');
    this.enhanceForm();

    this.modal = $(this.view('shared/_modal', {
      title: this.params.title,
      closeButtonCaption: "Cancel",
      okButtonCaption: this.params.okCaption
    }));

    this.modal.find('.modal-footer .btn.cancel').click((e) => {
      this.onCancel(e);
    });

    this.modal.find('.modal-footer .btn.ok').click((e) => {
      this.onOk(e);
    });

    this.modal.appendTo('body').modal()
    .on('hidden.bs.modal', () => {
      this.modal.remove();
      this.params.cbAfter();
    });

    this.modal.find('.modal-body').append(modalContent);
  }

  enhanceForm() {
    this.form.find('.datepicker input[type=text]').datepicker(settings.datepicer);
  }

  onCancel(e) {
    e.preventDefault();

    this.hideSpinner();
    this.close();
    this.params.cbAfter();
  }

  close() {
    this.modal.modal('hide');
  }

  onOk(e) {
    e.preventDefault();

    if (this.form.data('in_progress')) {
      return;
    }

    this.form.data('in_progress', true);

    this.showSpinner();

    Utils.submit({
      $form: this.form,
      cb_success: resp => {
        this.onSuccess(resp);
      },
      cb_error: (textStatus, errorThrown) => {
        this.onError(textStatus, errorThrown);
      },
      cb_after: () => {
        this.hideSpinner();
        this.form.data('in_progress', false);
      }
    });
  }

  // server error
  onError(textStatus, errorThrown) {
    alert(`error: ${textStatus}, ${errorThrown}`);
  }

  onSuccess(resp) {
    if (resp.errors) {
      const errors    = this.form.find('.errors');
      const newErrors = $($.parseHTML($.trim(resp.code)));

      if (errors.length > 0) {
        errors.hide(300, () => {
          errors.remove();
          newErrors.hide().prependTo(this.form).show(300);
        });
      } else {
        newErrors.hide().prependTo(this.form).show(300);
      }
    } else {
      this.close();
      this.params.cbSuccess(resp);
      this.params.cbAfter();
    }
  }

  showSpinner() {
    this.modal.find('.modal-footer .spinner').removeClass('hidden');
  }

  hideSpinner() {
    this.modal.find('.modal-footer .spinner').addClass('hidden');
  }
}
